frappe.provide('healthcare');

class TokenPopup {
	constructor(token) {
		console.log('TokenPopup: ', token);
		if (token.queue_empty) {

			this.dialog = frappe.msgprint(__('No more Patients in queue!'));

		} else {
			this.token = token.token;
			this.token_number = token.token_number;
			this.queue_dt = token.queue_dt;
			this.queue_dn = token.queue_dn;
			this.form_dt = token.form_dt;
			this.route_to_list_view = token.route_to_list_view;
			this.patient = token.patient;
			this.patient_name = token.patient_name;
			this.patient_age = token.patient_age;
			this.patient_gender = token.patient_gender;
			this.patient_img = token.image
			this.make_dialog()

		}
	}
	make_dialog() {
		this.dialog = new frappe.ui.Dialog({
			'static': true,
			'minimizable': true,
			'size': 'small'
		});
		// this.dialog.get_close_btn().show();
		this.setup_dialog();
		frappe.utils.bind_actions_with_object(this.dialog.$body, this);
		// this.dialog.$wrapper.addClass('call-popup');

		frappe.utils.play_sound('alert');
		this.dialog.show();
	}

	setup_dialog() {
		this.setup_token_details();
		this.setup_patient_details_html();
		this.dialog.set_title(`TOKEN: ${this.token_number}`);
		this.dialog.$body.empty().append(this.token_info);
	}

	setup_token_details() {
		this.token_info = $(`<div></div>`);
		this.token_details = new frappe.ui.FieldGroup({
			fields: [{
				'fieldtype': 'HTML',
				'fieldname': 'token_details'
			}, {
				'fieldtype': 'Button',
				'label': __('Create Patient'),
				'click': () => this.create_patient.bind(this),
				'depends_on': () => !this.patient
			}, {
				'fieldtype': 'Section Break',
			}, {
				'fieldtype': 'Button',
				'label': __(`Create New ${this.form_dt}`),
				'click': this.new_form_dt.bind(this),
				'depends_on': () => this.form_dt && !this.route_to_list_view
			}, {
				'fieldtype': 'Button',
				'label': __(`Go To ${this.form_dt} List`),
				'click': () => frappe.set_route('List', this.form_dt, {patient: this.patient}),
				'depends_on': () => this.form_dt && this.route_to_list_view
			}, {
				'fieldtype': 'Column Break',
			}, {
				'fieldtype': 'Button',
				'label': __('Attend'),
				'click': () => {
					frappe.call('patient_queue_management.patient_queue_management.doctype.patient_token.patient_token.update_token', {
						token: this.token,
						action: 'attend',
						queue_dn: this.queue_dn
					}).then(() => {
						// TODO: start timer
						frappe.show_alert({
							message: __('Start Time Recorded'),
							indicator: 'alert'
						});
					});
				}
			}, {
				'fieldtype': 'Column Break',
			}, {
				'fieldtype': 'Button',
				'label': __('Complete'),
				'click': () => {
					frappe.call('patient_queue_management.patient_queue_management.doctype.patient_token.patient_token.update_token', {
						token: this.token,
						action: 'check_out',
						queue_dn: this.queue_dn
					}).then(() => {
						this.close_dialog();
					});
				}
			}],
			body: this.token_info
		});
		this.token_details.make();
	}

	close_dialog() {
		this.dialog.hide();
		delete healthcare.token_popup;
	}

	new_form_dt() {
		const new_doc = frappe.model.get_new_doc(this.form_dt);
		new_doc.patient = this.patient;
		frappe.set_route('Form', new_doc.doctype, new_doc.name);
	}

	create_patient() {
		const new_doc = frappe.model.get_new_doc('Patient');
		frappe.set_route('Form', new_doc.doctype, new_doc.name);
	}

	setup_patient_details_html() {
		if (this.patient) {
			this.token_details.get_field('token_details').html(`
				<div class="token-details jumbotron style="text-align:left background-color:blue;">
					<div style="padding-bottom:18px;">
					<div style= "float:left;">
							${this.patient_img ? `<img src="${this.patient_img}" style="border-radius: 15%;" width=100 height=100>`
							:`<img src="/assets/frappe/images/default-avatar.png" style="border-radius: 15%;" width=100 height=100>`}
						</div>
						<div style="padding-left:130px; padding-top:10px;">
							<div class="text-muted ml-1">
							${__("Patient:")}
								<b><a href="/app/patient/${this.patient}" title="${__("Patient")}">${this.patient}</b></a>
							</div>
							<div class="text-muted ml-1">
							${__("Name:")}
								<b> ${this.patient_name?this.patient_name:""}</b>
							</div>
							<div class="text-muted ml-1">
							${__("Age:")}
								<b> ${this.patient_age?this.patient_age:""}</b>
							</div>
							<div class="text-muted ml-1">
							${__("Gender:")}
								<b> ${this.patient_gender?this.patient_gender:""}</b>
							</div>
						</div>
					</div>
				</div>`
			);
		} else {
			this.token_details.get_field('token_details').html(`
				<div class="token-details">No Patient Information Available</div>
			`)
		}
	}
}

$(document).on('app_ready', function () {

	frappe.realtime.on('refresh_queue_kanban', (data) => {
		console.log(data);
		// TODO refresh kanban
	});

	frappe.realtime.on('next_token', (data) => {

		console.log(data);

		// allow multiple
		if (healthcare.token_popup) {
			healthcare.token_popup.close_dialog()
		}

		healthcare.token_popup = new TokenPopup(data)

	});
});

window.TokenPopup = TokenPopup;
